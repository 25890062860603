import React from 'react';
import { Card, Theme, color } from '@morressier/ts';
import { useTheme } from 'styled-components';
import { RowsSkeletonBox, RowsSkeletonProps } from './Rows';

interface CardSkeletonProps {
  minHeight?: string;
}

export const CardSkeleton: React.FC<CardSkeletonProps & RowsSkeletonProps> = ({
  minHeight,
  rows,
}) => {
  const theme = useTheme() as typeof Theme;

  return (
    <Card
      distribution="vertical"
      style={{
        minHeight: minHeight ?? '16rem',
        borderRadius: '3px',
        border: '1px solid #d9dbdf',
        background: color('white')({ theme }),
      }}
    >
      {' '}
      <RowsSkeletonBox rows={rows} />
    </Card>
  );
};
