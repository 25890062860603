import React from 'react';
import { StatusChip } from '@morressier/ts';
import { logEvent } from 'utils/eventTracking';

type StatusChipArticleProps = {
  keyword: string;
  href?: string;
};

const StatusChipArticle: React.FC<StatusChipArticleProps> = props => (
  <a
    href={props.href}
    onClick={() => {
      logEvent('KEYWORD_CLICKED', {
        keyword: props.keyword,
      });
    }}
  >
    <StatusChip
      key={props.keyword}
      style={{
        marginTop: '0.5rem',
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
        cursor: props.href ? 'pointer' : 'auto',
      }}
      bgColor="sidebarGrey"
      borderColor="lightGrey"
      textColor="secondaryBrand"
      chipSize="large"
    >
      {props.keyword}
    </StatusChip>
  </a>
);

export default StatusChipArticle;
