import QueryString from 'qs';
import httpService from 'services/httpService';
import { parseQuery } from './utils';

export const fetchPosterStats = (eventId: string, posterId: string) =>
  httpService
    .get<MorressierPosterStats>({
      url: `api/v3/standalone/events/${eventId}/posters/${posterId}/stats`,
    })
    .then(response => {
      const mapProps = {
        total_views: 'views',
        web_views: 'webViews',
        web_bookmarks: 'bookmarks',
        web_impressions: 'webImpressions',
        web_downloads: 'downloads',
        app_views: 'appViews',
        app_shares: 'shares',
      };
      const data = Object.keys(response.data).reduce(
        (dt, key) => ({
          ...dt,
          [mapProps[key]]: Math.ceil(response.data[key]),
        }),
        {} as MorressierPosterStats,
      );
      return data;
    })
    .catch(() => null);

export const fetchPosterThumnails = (eventId: string, posterIds: string[]) =>
  httpService
    .get<MorressierPosterThumbnails[]>({
      url: `api/v3/standalone/events/${eventId}/thumb-urls?${QueryString.stringify(
        parseQuery({
          posters: posterIds.join(','),
        }),
      )}`,
    })
    .then(response => response.data)
    .catch(() => null);

export const fetchPosterKeywords = (eventId: string, posterId: string, userAdded: boolean) =>
  httpService
    .get<string[]>({
      url: `api/v3/standalone/events/${eventId}/posters/${posterId}/keywords?user-added=${String(
        userAdded,
      )}`,
    })
    .then(response => response.data)
    .catch(() => []);

export const fetchPosterMuxThumbnail = (url: string) =>
  httpService.get<{ config: { url: string } }>({ url }).then(res => res.data);

export const fetchPosterDiscussion = (eventId: string, posterId: string) =>
  httpService
    .get<string[]>({
      url: `api/v3/standalone/events/${eventId}/posters/${posterId}/discussions`,
    })
    .then(response => response.data)
    .catch(() => []);
