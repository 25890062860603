import React, { ReactElement } from 'react';
import { CardContent, RectangleSkeleton } from '@morressier/ts';

export interface RowsSkeletonProps {
  rows?: number;
}

export const RowsSkeleton: React.FC<RowsSkeletonProps> = ({ rows = 2 }) => {
  const rectangles: ReactElement[] = [];

  for (let i = 0; i < rows; i += 1) {
    rectangles.push(<RectangleSkeleton key={i} width="100%" className="mt1" />);
  }

  return (
    <>
      <RectangleSkeleton width="100%" style={{ width: '100px', paddingBottom: '1rem' }} />
      {rectangles}
    </>
  );
};

export const RowsSkeletonBox: React.FC<RowsSkeletonProps> = ({ rows }) => (
  <CardContent>
    <RowsSkeleton rows={rows} />
  </CardContent>
);
