import React, { FC } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { fetchPosterMuxThumbnail } from 'api/poster';

type Params = {
  width?: number;
  height?: number;
};

/**
 *
 * @deprecated
 */
const getMuxThumbnail = async (playbackId: string, params?: Params) => {
  try {
    const res = await axios.get(
      `https://image.mux.com/${playbackId}/thumbnail.png?width=${params?.width ?? 106}&height=${
        params?.height ?? 60
      }`,
    );

    return res.config.url;
  } catch (error) {
    return '';
  }
};

export const getPlaybackId = (playbackUrl?: string) => {
  if (playbackUrl) {
    const split = playbackUrl.split('https://stream.mux.com/');
    const playbackId = split.join('').split('.m3u8');

    return playbackId?.[0];
  }

  return null;
};

export const useMuxThumbnail = (playbackId: string | null, params?: Params) => {
  const url = playbackId
    ? `/o/api/mux-thumbnail-api/${playbackId}?width=${params?.width ?? 106}&height=${
        params?.height ?? 60
      }`
    : null;

  const { data } = useSWR<{ config: { url: string } }>(url, fetchPosterMuxThumbnail, {
    revalidateOnFocus: false,
  });

  return data?.config?.url ?? '';
};

const MuxThumbnail: FC<{
  playbackId: string;
  params?: Params;
  className?: string;
  style?: React.CSSProperties;
}> = props => {
  const thumbnailUrl = useMuxThumbnail(props.playbackId, props.params);

  return (
    <img
      alt=""
      src={thumbnailUrl}
      loading="lazy"
      width={props.params?.width}
      height={props.params?.height}
      style={props.style}
      className={props.className}
    />
  );
};

export default MuxThumbnail;
