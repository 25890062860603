import * as React from 'react';
import Link, { LinkProps } from 'next/link';
import * as Styles from './style';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  previousButtonText?: string;
  nextButtonText?: string;
  numberOfPages?: number;
  pageRouteBuilder: (page: number) => LinkProps['href'];
  onPageChange?: (page: number) => void;
};

export const PageLink: React.FC<LinkProps & { isActive?: boolean; onClick?: () => void }> = ({
  children,
  isActive,
  ...props
}) => (
  <Link {...props}>
    <Styles.PageButton onClick={() => props?.onClick && props?.onClick()} isActive={isActive}>
      {children}
    </Styles.PageButton>
  </Link>
);

const Paging = (props: PaginationProps) => {
  const { defaultProps } = Paging;
  const {
    currentPage,
    totalPages,
    numberOfPages = defaultProps.numberOfPages,
    previousButtonText = defaultProps.previousButtonText,
    nextButtonText = defaultProps.nextButtonText,
    onPageChange = () => null,
  } = props;

  const pagesToRender = Math.min(totalPages, numberOfPages);

  const firstPageToRender =
    currentPage > numberOfPages / 2 ? Math.ceil(currentPage - numberOfPages / 2) : 1;
  const lastPageToRender = Math.min(firstPageToRender + pagesToRender - 1, totalPages);

  const pageNumbers: number[] = [];
  for (let i = firstPageToRender; i <= lastPageToRender; i += 1) {
    pageNumbers.push(i);
  }

  return totalPages > 1 ? (
    <Styles.PaginationControlsWrapper>
      {currentPage > 1 && (
        <PageLink
          href={props.pageRouteBuilder(currentPage - 1)}
          passHref
          onClick={() => onPageChange(currentPage - 1)}
        >
          <div
            style={{
              width: '8rem',
            }}
            color="secondary"
          >
            {previousButtonText}
          </div>
        </PageLink>
      )}
      <Styles.PageNumbers>
        {pageNumbers.map((i: number) => (
          <PageLink
            href={props.pageRouteBuilder(i)}
            key={i}
            isActive={i === currentPage}
            passHref
            onClick={() => onPageChange(i)}
          >
            <Styles.PageNumber currentPage={i === currentPage}>{i}</Styles.PageNumber>
          </PageLink>
        ))}
      </Styles.PageNumbers>
      {currentPage < totalPages && (
        <PageLink
          href={props.pageRouteBuilder(currentPage + 1)}
          onClick={() => onPageChange(currentPage + 1)}
          passHref
        >
          <div
            color="secondary"
            style={{
              width: '8rem',
            }}
          >
            {nextButtonText}
          </div>
        </PageLink>
      )}
    </Styles.PaginationControlsWrapper>
  ) : null;
};

Paging.defaultProps = {
  previousButtonText: 'PREVIOUS',
  nextButtonText: 'NEXT',
  numberOfPages: 10,
} as const;

export default Paging;
