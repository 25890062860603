import styled, { css } from 'styled-components';
import { media, Text } from '@morressier/ts';

export const PaginationControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 1rem;
  ${media.large`
    margin-top: 5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
    flex-wrap: nowrap;
  `};
`;

export const PageNumbers = styled.div`
  order: 1;
  display: flex;
  justify-content: center;
  min-width: 100%;
  margin: 1.5rem 0 0 0;
  flex-wrap: wrap;

  ${media.large`
    margin: 0 2.25rem;
    min-width: auto;
    order: 0;
  `};
`;

type NumberProps = { currentPage: boolean };

export const PageNumber = styled(Text).attrs(() => ({
  textAs: 'span',
}))<NumberProps>`
  display: inline-block;
  cursor: pointer;
  padding: 0 0.5rem;
`;

export const PageButton = styled.a<{ isActive?: boolean }>`
  text-decoration: none;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424d5d;

  ${props =>
    props.isActive
    && css`
      outline: 4px solid lightblue;
      border: 1px solid grey;
    `}
`;
