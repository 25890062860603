import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import {
  Text,
  color,
  HTMLText,
  Card,
  Icon,
  CardContent,
  CardFooter,
  CardMedia,
} from '@morressier/ts';
import { useRouter } from 'next/router';
import { fetchPosterAuthors } from 'services/documents';
import { FaDownload, FaRegPlayCircle, FaImage, FaEye, FaLock } from 'react-icons/fa';
import Dotdotdot from 'react-dotdotdot';
import { getPlaybackId, useMuxThumbnail } from 'components/MuxThumbnail';
import { fetchPosterKeywords, fetchPosterStats } from 'api/poster';
import { logEvent } from 'utils/eventTracking';
import useSWR from 'swr';
import StatusChipArticle from '../Article/StatusChipArticle';

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EventName = styled(Text).attrs({
  color: 'grey',
})`
  margin-right: 0.25rem;
`;

export const Title = styled(Text).attrs({
  textAs: 'a',
})`
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.1rem;
`;

export const StyledStatsNumber = styled(Text).attrs({
  color: 'secondaryBrand',
})`
  margin-left: 0.25rem;
`;

export const ViewsIcon = styled(FaEye)`
  color: ${color('grey')};
`;

export const DownloadsIcon = styled(FaDownload)`
  color: ${color('grey')};
`;

export const PlayCircleIcon = styled(FaRegPlayCircle)`
  margin-bottom: 0.25rem;
  color: ${color('grey')};
`;

export const EmbargoedIcon = styled(FaLock)`
  margin-bottom: 0.25rem;
`;

export const AnchorCard = styled.a`
  text-decoration: none;
`;

export type DistributionCardType = 'horizontal' | 'vertical';

interface PosterCardProps {
  id: string;
  title: string;
  distribution: DistributionCardType;
  authors: string[];
  event: string;
  stats: {
    views: number;
    downloads: number;
  };
  thumbUrl?: string;
  filetype: string;
  showThumbnail?: boolean;
  cardStyle?: React.CSSProperties;
  embargoed?: boolean;
  keywords?: string[];
  submissionType?: 'SUBMISSION' | 'PRESENTATION' | 'DOCUMENT';
  videoPresentation?: MorressierVideoPresentation | null;
  contentLibrary?: string;
}

const defaultProps: Partial<PosterCardProps> = {
  thumbUrl: '',
  cardStyle: {},
  embargoed: false,
  keywords: [],
  submissionType: 'SUBMISSION',
  showThumbnail: true,
  videoPresentation: null,
};

const PosterCard: React.FC<PosterCardProps> = props => {
  const {
    id,
    title,
    distribution,
    authors,
    thumbUrl,
    cardStyle,
    event,
    embargoed,
    submissionType,
    showThumbnail,
    videoPresentation,
    filetype,
    contentLibrary,
  } = props;

  const { query } = useRouter();
  const playbackId = getPlaybackId(videoPresentation?.playback_url);
  const videoThumbnail = useMuxThumbnail(playbackId, { width: 520, height: 400 });
  const { data: stats } = useSWR(
    event && id ? [event, id, 'poster-stats'] : null,
    fetchPosterStats,
    {
      revalidateOnFocus: false,
    },
  );
  const { data: keywords } = useSWR(event && id ? [event, id, true] : null, fetchPosterKeywords, {
    revalidateOnFocus: false,
  });

  const { data: authorsResponse } = useSWR(event && id ? [id, true] : null, fetchPosterAuthors, {
    revalidateOnFocus: false,
  });

  const {
    contentLibrary: contentLibrary_,
    contentLibraryTitle,
    fromSearch,
    from,
    libraryType,
  } = query;

  const authorsToShow =
    authorsResponse?.map(author => author.full_name) || authors.filter(a => !!a);

  const getThumbnail = video_presentation => {
    if (video_presentation && videoThumbnail) {
      return { url: videoThumbnail };
    }

    if (filetype === 'pdf' && thumbUrl) {
      return { url: thumbUrl };
    }

    return { url: '', icon: FaImage };
  };

  const thumbnail = getThumbnail(videoPresentation);

  const linkQuery = {
    eventId: event,
    article_id: id,
    ...(fromSearch ? { fromSearch } : {}),
    ...(contentLibrary_ ? { contentLibrary: contentLibrary_, contentLibraryTitle } : {}),
    ...(from ? { from } : {}),
    ...(libraryType ? { libraryType } : {}),
  };

  return (
    <Link
      passHref
      href={{
        pathname: '/event/[eventId]/article/[article_id]',
        query: linkQuery,
      }}
    >
      <AnchorCard
        onClick={() => {
          logEvent('STANDALONE_CLICKED_SUBMISSION_CARD', {
            submission_id: id,
          });
        }}
      >
        <Card
          distribution={distribution}
          style={{
            cursor: 'pointer',
            height: '100%',
            ...cardStyle,
          }}
          onClick={() => {
            /* TODO https://morressier.atlassian.net/browse/ORG-157 */
          }}
        >
          {showThumbnail && (
            <CardMedia
              url={thumbnail.url}
              title={`Poster: ${title}`}
              overlay={thumbnail.icon && <Icon as={thumbnail.icon} size="2rem" />}
            />
          )}
          <CardContent>
            <StyledRowContainer>
              <Text color="grey" size="body1_new">
                {submissionType}
              </Text>
              {embargoed && <EmbargoedIcon color="grey" className="ml1" />}
              {videoPresentation && <PlayCircleIcon className="ml1" />}
            </StyledRowContainer>
            <Title fontWeight="bold" textAs="a" size="body1_new">
              <Dotdotdot clamp={3} useNativeClamp>
                <HTMLText>{title}</HTMLText>
              </Dotdotdot>
            </Title>
            <Text color="grey" size="body1_new">
              {`${authorsToShow.length > 0 ? authorsToShow[0] : ''} ${
                authorsToShow.length > 1
                  ? `and ${authorsToShow.length - 1} ${
                      authorsToShow.length > 2 ? 'others' : 'other'
                    }`
                  : ''
              }`}
            </Text>
          </CardContent>
          <CardFooter>
            <div>
              {keywords?.find((_, i) => i === 0) && (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <StatusChipArticle keyword={keywords[0]} />
                  {keywords.length > 1 && (
                    <Text color="grey" size="body2_new">{`and ${keywords.length - 1} more`}</Text>
                  )}
                </div>
              )}
              <StyledRowContainer>
                {!!stats?.views && (
                  <StyledRowContainer className="mr2">
                    <ViewsIcon />
                    <StyledStatsNumber>{stats?.views}</StyledStatsNumber>
                  </StyledRowContainer>
                )}
                {!!stats?.downloads && (
                  <StyledRowContainer className="mr2">
                    <DownloadsIcon />
                    <StyledStatsNumber>{stats?.downloads}</StyledStatsNumber>
                  </StyledRowContainer>
                )}
                {/* {typeof poster?.web_bookmarks === 'number' && ( -> hide this while we re-implement saving
                <StyledRowContainer>
                  <SavesIcon color="grey" />
                  <StyledStatsNumber>{poster?.web_bookmarks}</StyledStatsNumber>
                </StyledRowContainer>
              )} */}
              </StyledRowContainer>
            </div>
          </CardFooter>
        </Card>
      </AnchorCard>
    </Link>
  );
};

PosterCard.defaultProps = defaultProps;

export default PosterCard;
