import React from 'react';

import { color, CardGrid, Theme } from '@morressier/ts';

import styled, { useTheme } from 'styled-components';

import { CardSkeleton } from 'components/Skeleton/Card';

import PosterCard, { DistributionCardType } from '../PosterCard';

export type DistributionCardGridType = 'grid' | 'list';
export type SubmissionType = 'SUBMISSION' | 'PRESENTATION' | 'DOCUMENT';

interface PosterGridProps {
  items: any[];
  event?: string;
  distribution?: DistributionCardGridType;
  submissionType?: SubmissionType;
  showThumbnail?: boolean;
}

const defaultProps = {
  event: '',
  distribution: 'grid',
  submissionType: undefined,
  showThumbnail: true,
};

const StyledPosterGridContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
`;

const PosterGrid = ({
  items,
  event,
  distribution,
  submissionType,
  showThumbnail,
}: PosterGridProps) => {
  const compact = false;
  const theme = useTheme() as typeof Theme;

  return (
    <StyledPosterGridContainer>
      <CardGrid distribution={distribution}>
        {/* First Map the received object to a generic prop names and allow backwards compatibility */}
        {items?.map(
          ({
            _id,
            id: idMap,
            authors_v2,
            authors: authorsMap,
            title,
            event: posterEvent,
            web_downloads,
            downloads: downloadsMap,
            web_views,
            views: viewsMap,
            keywords,
            video_presentation,
            thumb_url,
            filetype,
          }) => {
            const { id, authors, downloads, views } = {
              id: idMap || _id,
              authors: authors_v2 || authorsMap,
              downloads: web_downloads || downloadsMap,
              views: Math.ceil(web_views || viewsMap),
            };

            const cardProps = {
              keywords,
              submissionType,
              showThumbnail,
              videoPresentation: video_presentation,
              thumbUrl: thumb_url,
              filetype,
            };

            if (!title) {
              return <CardSkeleton key={id} />;
            }

            return (
              <PosterCard
                key={id}
                id={id}
                title={title}
                distribution={
                  { grid: 'vertical', list: 'horizontal' }[
                    distribution as string
                  ] as DistributionCardType
                }
                event={event || posterEvent}
                authors={authors?.map(({ full_name }) => full_name) || []}
                stats={{
                  views,
                  downloads,
                }}
                cardStyle={
                  !compact
                    ? {
                        minHeight: '16rem',
                        background: color('white')({ theme }),
                      }
                    : {
                        minHeight: '21rem',
                        background: color('white')({ theme }),
                      }
                }
                {...cardProps}
              />
            );
          },
        )}
      </CardGrid>
    </StyledPosterGridContainer>
  );
};

PosterGrid.defaultProps = defaultProps;

export default PosterGrid;
