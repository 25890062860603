import * as React from 'react';
import Head from 'next/head';
import getConfig from 'next/config';

export type Props = {
  title?: string;
  description?: string;
  thumbnail?: string;
  favicon?: string;
  paginated?: {
    lastPage: number;
    path: string;
  };
  canonical?: string;
  noindex?: boolean;
  citation_doi?: string;
};

export function MetaTags(props: Props) {
  const { publicRuntimeConfig } = getConfig();

  return (
    <Head>
      <title>{props.title}</title>
      {(props.favicon || props.thumbnail) && (
        <link rel="shortcut icon" href={props.favicon || props.thumbnail} />
      )}
      <meta name="description" content={props.description} key="description" />
      <meta property="og:title" content={props.title} key="og:title" />
      <meta property="og:description" content={props.description} key="og:description" />
      <meta
        property="og:image"
        content={props.thumbnail || '/shared/metaDefault.png'}
        key="og:image"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.title} key="twitter:title" />
      <meta name="twitter:description" content={props.description} key="twitter:description" />
      <meta
        name="twitter:image"
        content={props.thumbnail || '/shared/metaDefault.png'}
        key="twitter:image"
      />
      {(props.noindex || publicRuntimeConfig.APP_ENV === 'staging') && (
        <meta name="robots" content="noindex" />
      )}
      <meta name="google-site-verification" content="t8_dg-N4OXVuHmGFB45ninmseZUrX3xD7r1ScBfzLgQ" />
      {props.citation_doi && <meta name="citation_doi" content={props.citation_doi} />}
      {props.canonical && <link rel="canonical" href={props.canonical} />}
    </Head>
  );
}

MetaTags.defaultProps = {
  title: 'Morressier',
  description: 'Welcome to the largest platform for early-stage research',
};
