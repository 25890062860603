import getConfig from 'next/config';
import useSWR from 'swr';
import httpService from './httpService';

const { publicRuntimeConfig } = getConfig();

export type FetchDocumentResponse = {
  id: string;
  event: string;
  pdfUrl: string;
  authors: string[];
};
export type FetchDocumentPathResponse = {
  organization_id?: string;
  event_id: string;
  document_id: string;
};
/*
const swrOptions = {
     revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
}
*/

// const DOCUMENT_API = process.env.DOCUMENT_API || 'http://localhost:4000';

const repo_api = publicRuntimeConfig?.repo_api;

export type FetchDocumentType = {
  //

  _co_authors: string[];
  created_at: string;
  poster_id: string;
  //
  _legacy_abstract_id: string;
  additional_file_urls: string[];
  archive: boolean;
  authors: string[];
  doi: string;
  doi_requested: boolean;
  embargoed: boolean;
  event: string;
  fileid: string;
  filetype: string;
  id: string;
  dpc_conference_name: string;
  keywords: string[];
  license: number;
  orcid_sync_requested: boolean;
  paper_abstract: string;
  paper_abstract_markdown: string;
  processing_state: number;
  public_access_enabled: true;
  submission_completed: true;
  submitted_at: string;
  title: string;
  uploaded_at: string;
  uploader: string;
  video_presentation: {
    file_name: string;
    playback_url: string;
    processing_state: 2;
    upload_url: string;
  };
  withdrawn: boolean;
  thumb_url: string;
  thumb_url_medium: string;
  thumb_url_large: string;
  public_stats: {
    views: number;
    shares: number;
    appViews: number;
    webViews: number;
    downloads: number;
    bookmarks: number;
    webImpressions: number;
  };
  pdfUrl: string;
  organization_id?: string;
  origin?: string;
  type?: string;
};

export const fetchDocumentPath = (document_id: string) =>
  httpService
    .get<FetchDocumentPathResponse>({ url: `${repo_api}/documents/${document_id}` })
    .then(res => res.data);

export const fetchDocument = ({
  organization_id,
  event_id,
  document_id,
}: {
  organization_id: string;
  event_id: string;
  document_id: string;
}) =>
  httpService
    .get<FetchDocumentType>({
      url: `${repo_api}/organizations/${organization_id}/events/${event_id}/documents/${document_id}`,
    })
    .then(res => res.data)
    .catch(() => null);

// TODO: Revisit this
export const fetchDocumentV2 = (organization_id: string, event_id: string, document_id: string) =>
  httpService
    .get<FetchDocumentType & MorressierPoster>({
      url: `${repo_api}/organizations/${organization_id}/events/${event_id}/documents/${document_id}`,
    })
    .then(res => res.data)
    .catch(() => null);

export const getDownloadPosterUrl = (posterId: string) =>
  httpService
    .get<string>({
      url: `${publicRuntimeConfig.api_base}/api/events_manager/v2/posters/${posterId}/download`,
    })
    .then(response => response.data)
    .catch(() => '');

export const getSignedDatasetUrl = (posterId: string) =>
  httpService
    .get<string[]>({
      url: `${publicRuntimeConfig.api_base}/api/v3/discovery/posters/${posterId}/dataset-urls`,
    })
    .then(response => response.data)
    .catch(() => []);

export const fetchDocumentAuthorsByIDs = async (authorIds: string[]) => {
  const resp = await Promise.all(
    authorIds.map(author_id =>
      httpService
        .get<MorressierUser[]>({
          url: `${publicRuntimeConfig.api_base}/api/v1/discovery/users/${author_id}`,
        })
        .then(res => res.data),
    ),
  ).catch(() => []);
  return resp.map(u => u.user) as MorressierUser[];
};

export const fetchDocumentAuthorsByIDsV3 = async (authorIds: string[]) => {
  const resp = await httpService
    .get<MorressierUser[]>({
      url: `${publicRuntimeConfig.api_base}/api/v3/discovery/users?${authorIds
        .map(id => `user_ids=${id}`)
        .join('&')}`,
    })
    .then(res => res.data);
  return resp;
};

export const fetchDocumentVideo = (path: string) =>
  httpService
    .get<{ signed_url: string }>({ url: `${repo_api}${path}/video` })
    .then(res => res.data);

export const useDocumentVideo = ({ organization_id, event_id, article_id, canShowVideo }) => {
  const { data, error } = useSWR(
    canShowVideo && organization_id
      ? `/organizations/${organization_id}/events/${event_id}/documents/${article_id}`
      : null,
    fetchDocumentVideo,
    { revalidateOnFocus: false },
  );

  return { data, error, isLoading: !data && !error };
};

export const fetchPosterAuthors = async (posterId: string) => {
  const resp = await httpService
    .get<MorressierUser[]>({
      url: `${publicRuntimeConfig.api_base}/api/v3/content-library/posters/${posterId}/authors`,
    })
    .then(res => res.data);
  return resp;
};
